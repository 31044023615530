import './NavItem.css'
import { FaAsterisk } from 'react-icons/fa'

import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

const NavItem = ({ path, text, classes, closeFunction }) => {
    const isDisabled = classes.includes('disabled-menu')

    return (
        <li className={`${classes} ${isDisabled ? 'disabled-menu' : ''}`}>
            {isDisabled ? (
                <>
                    <span className="flex items-center space-x-2">
                        <span>{text}</span>
                        <FaAsterisk className="text-accent ml-1" />
                    </span>
                </>
            ) : (
                <NavLink onClick={closeFunction} to={path}>
                    {text}
                </NavLink>
            )}
        </li>
    )
}

NavItem.propTypes = {
    path: PropTypes.string,
    text: PropTypes.string,
    classes: PropTypes.string,
    closeFunction: PropTypes.func,
}

export default NavItem
