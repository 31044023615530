import _fetch from '.'

export async function getProfile(options = {}) {
    const url = `${process.env.REACT_APP_API_URL}/users/me`
    return _fetch(url, options)
}

export async function updateProfile(options = {}) {
    const url = `${process.env.REACT_APP_API_URL}/users/me`
    return _fetch(url, options)
}

export async function updateAdminProfile(id, options = {}) {
    const url = `${process.env.REACT_APP_API_URL}/users/admins/${id}`
    return _fetch(url, options)
}

export async function getUsers(options = {}) {
    const url = `${process.env.REACT_APP_API_URL}/users`
    return _fetch(url, options)
}

export async function sendUpdateAdminPasswordRequest(options = {}) {
    const url = `${process.env.REACT_APP_API_URL}/users/admins/reset-password`
    return _fetch(url, options)
}

export async function updateAdminPassword(options = {}) {
    const url = `${process.env.REACT_APP_API_URL}/users/admins/auth/reset-password`
    return _fetch(url, options)
}
