/* eslint-disable react/prop-types */

import { useState } from 'react'
import { getPaymentTypeText } from '../../helpers/payments'
import { PaymentFormTypes } from '../../enums/PaymentTypes'
import SelectFieldMobile from '../SelectField/SelectFieldMobile'
import PopupMessage from '../PopupMessage/PopupMessage'
import { IoInformationCircleSharp } from 'react-icons/io5'
import bill from '../../assets/images/logariasmos.png'

const FormBodyMobile = props => {
    const [paymentType, setPaymentType] = useState('')
    const [popup, setPopup] = useState({ message: '', type: '', shown: false })

    const toggleHydrometerInfo = () => {
        setPopup(values => ({
            ...values,
            shown: true,
            message: (
                <>
                    <h1 className="font-bold">Υπόδειγμα Υδρόμετρου</h1>
                </>
            ),
            popupImage: (
                <>
                    <img src={bill} alt="bill" />
                </>
            ),
            type: 'image',
        }))
    }

    const line = (text, type, name, euro, infoButton) => (
        <div className="flex w-full justify-between gap-3 border-b border-gray px-4 pb-1 pt-4 mr-5 flex-col md:flex-row relative">
            <div className="flex justify-between w-full md:w-2/6">
                <p className="font-semibold">{text}</p>
                {euro && <p className="font-semibold">€</p>}
            </div>
            <input
                className="md:self-end text-secondary flex-1 w-2/3"
                placeholder="Εισαγωγή.."
                name={name}
                type={type}
                min={0}
                onChange={props.handleChange}
            />
            {infoButton && (
                <button
                    type="button"
                    className="absolute right-4 top-1/2 transform -translate-y-1/2"
                    onClick={toggleHydrometerInfo}
                >
                    <IoInformationCircleSharp className="text-[#84CDEA] text-2xl hover:text-primary transition" />
                </button>
            )}
        </div>
    )

    const options = [
        {
            value: PaymentFormTypes.hospitalityTax,
            text: getPaymentTypeText(PaymentFormTypes.hospitalityTax),
        },
        {
            value: PaymentFormTypes.communalAreasTax,
            text: getPaymentTypeText(PaymentFormTypes.communalAreasTax),
        },
        {
            value: PaymentFormTypes.waterBill,
            text: getPaymentTypeText(PaymentFormTypes.waterBill),
        },
        {
            value: PaymentFormTypes.propertyContract,
            text: getPaymentTypeText(PaymentFormTypes.propertyContract),
        },
        {
            value: PaymentFormTypes.tombAndReconditioningPermit,
            text: getPaymentTypeText(
                PaymentFormTypes.tombAndReconditioningPermit
            ),
        },
        {
            value: PaymentFormTypes.gravePurchase,
            text: getPaymentTypeText(PaymentFormTypes.gravePurchase),
        },
    ]

    return (
        <form onSubmit={props.submitHandler}>
            {popup.shown && (
                <PopupMessage
                    message={popup.message}
                    type={popup.type}
                    closePopUp={() =>
                        setPopup(values => ({ ...values, shown: false }))
                    }
                    showCloseButton={true}
                    popupImage={popup.popupImage}
                />
            )}
            <div className="w-full flex flex-col">
                {line('Κωδικός Οφειλής', 'text', 'paymentCode')}
                {line('Αιτιολογία', 'text', 'description')}
                {line('Ποσό', 'number', 'amount', true)}
                {line('Διεύθυνση', 'text', 'address')}

                <SelectFieldMobile
                    onChange={e => {
                        setPaymentType(e.target.value)
                        props.handleChange(e)
                    }}
                    name={'paymentType'}
                    text={'Τύπος Πληρωμής'}
                    options={options}
                />

                {paymentType === PaymentFormTypes.waterBill &&
                    line('Υδρόμετρο', 'text', 'hydrometer', false, true)}

                <div className="self-center py-10">
                    <button
                        className="w-fit py-2 px-8 bg-primary text-white border border-primary hover:bg-white hover:text-primary transition-all rounded-lg"
                        type="submit"
                    >
                        Πληρωμή
                    </button>
                </div>
            </div>
        </form>
    )
}

export default FormBodyMobile
