import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import Modal from '../components/Modal/Modal'

import userIcon from '../assets/images/user-icon.svg'
import taxisIcon from '../assets/images/taxis-icon.png'

import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import useAuth from '../hooks/useAuth'
import useMediaQuery from '../hooks/useMediaQuery'

const Login = () => {
    const { isLoggedIn, taxisLogin } = useAuth()

    const [queryParams] = useSearchParams()
    const taxisToken = queryParams.get('t')
    const [errorMsgTaxis, seterrorMsgTaxis] = useState(
        queryParams.get('taxiserror')
    )

    const isMobile = useMediaQuery('(max-width:1024px)')
    const navigate = useNavigate()

    useEffect(() => {
        document.title = 'Σύνδεση - Πλατφόρμα Ηλ. Πληρωμών Δήμου Αντιπάρου'

        if (isLoggedIn()) {
            navigate('/')
        }

        if (taxisToken) {
            taxisLogin(taxisToken)
            if (!isLoggedIn()) {
                seterrorMsgTaxis(true)
            }
        }
    }, [])

    const divClasses = isMobile
        ? 'py-8 flex flex-col items-center'
        : 'py-16 px-28 text-center'

    const TaxisLogin = () => (
        <>
            <main className="flex justify-center items-center">
                <div className="py-16 px-10 text-center flex flex-col items-center">
                    <p className="font-light text-secondary text-l md:text-xl">
                        Αποκτήστε πρόσβαση σε προσωποποιημένες υπηρεσίες,
                        δημιουργήστε έναν λογαριασμό και παρακολουθήστε την
                        κατάσταση αιτημάτων καθώς και την θυρίδα σας. Για πλήρη
                        πρόσβαση στις e-Υπηρεσίες, συνδεθείτε μέσω TAXIS NET.
                    </p>

                    <div className="bg-accent rounded-full w-fit p-4 my-10">
                        <img width="30" height="30" src={userIcon} alt="user" />
                    </div>

                    <a
                        className="flex items-center gap-2 text-accent font-semibold text-lg shadow-md rounded-xl px-4 py-2 transition-all duration-200 ease-in-out border border-white hover:border-primary"
                        href={`${process.env.REACT_APP_API_URL}/auth/taxis/redirect`}
                    >
                        Σύνδεση με
                        <img src={taxisIcon} alt="TaxisNet" />
                    </a>

                    {errorMsgTaxis ? (
                        <div className=" underline font-semibold text-red-700 text-base pt-10">
                            Η διαδικασία ταυτοποίησης μέσω taxis-net απέτυχε.
                        </div>
                    ) : null}
                </div>
            </main>
        </>
    )

    return (
        <>
            <main className="flex justify-center items-center m-3">
                <Modal classes=" w-full h-fit m-5 md:max-w-5xl md:m-12">
                    <Header includeLogo={true} withBorder={true} />

                    <div className={divClasses}>
                        <h1 className="font-bold  text-primary text-3xl ">
                            Είσοδος
                        </h1>
                        <div className="w-full">
                            <TaxisLogin />
                        </div>
                    </div>
                </Modal>
            </main>

            <Footer />
        </>
    )
}

export default Login
