import { PaymentFormTypes } from '../enums/PaymentTypes'

export function getPaymentStatus(payments, paymentCode) {
    for (let i = 0; i < payments.length; i++) {
        if (payments[i].paymentCode === paymentCode) {
            return payments[i].status
        }
    }
    return null // payment with given paymentCode not found
}

export function getPaymentTypeText(value) {
    switch (value) {
        case PaymentFormTypes.hospitalityTax:
            return 'Τέλη Παρεπιδημούντων'
        case PaymentFormTypes.communalAreasTax:
            return 'Τέλη χρήσης κοινόχρηστων χώρων'
        case PaymentFormTypes.waterBill:
            return 'Λογαριασμός Ύδρευσης'
        case PaymentFormTypes.propertyContract:
            return 'Μισθώματα ακινήτων, βοσκοτόπων, λατομείων και δασικών εκτάσεων'
        case PaymentFormTypes.tombAndReconditioningPermit:
            return 'Δικαίωμα ανακομιδής και χρήσης οστεοφυλακίου'
        case PaymentFormTypes.gravePurchase:
            return 'Αγορά τάφου'
        case PaymentFormTypes.other:
            return 'Άλλο'
        default:
            break
    }
}
