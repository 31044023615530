import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import Modal from '../components/Modal/Modal'
import PopupMessage from '../components/PopupMessage/PopupMessage'
import FormField from '../components/FormField/FormField'

import {
    validatePassword,
    validatePasswordStrength,
} from '../helpers/validators'

import { useMutation } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { updateAdminPassword } from '../api/users'
import { useParams } from 'react-router-dom'

import useAuth from '../hooks/useAuth'
import useMediaQuery from '../hooks/useMediaQuery'

const ResetAdminPasswordForm = () => {
    const { isLoggedIn } = useAuth()
    const vToken = useParams().vToken

    const [popup, setPopup] = useState({ message: '', type: '', shown: false })
    const [inputs, setInputs] = useState({
        password: '',
        passwordVerification: '',
    })

    const isMobile = useMediaQuery('(max-width:1024px)')
    const navigate = useNavigate()

    useEffect(() => {
        document.title =
            'Επαναφορά κωδικού πρόσβασης - Πλατφόρμα Ηλ. Πληρωμών Δήμου Αντιπάρου'

        if (isLoggedIn()) {
            navigate('/')
        }
    }, [])

    const mutation = useMutation({
        mutationFn: () =>
            updateAdminPassword({
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${vToken}`,
                },
                body: JSON.stringify({
                    password: inputs.password,
                    passwordVerification: inputs.passwordVerification,
                }),
            }),
        onSuccess: () => {
            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <p>Έχετε αλλάξει επιτυχώς τον κωδικό πρόσβασής σας.</p>
                ),
                type: 'success',
            }))
        },
        onError: () => {
            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <>
                        <p>Κάτι πήγε στραβά! </p>
                        <p>Παρακαλούμε προσπαθήστε ξανά.</p>
                    </>
                ),
                type: 'error',
            }))
        },
    })

    const handleSubmit = e => {
        e.preventDefault()

        const isPasswordStrongEnough = validatePasswordStrength(inputs.password)

        if (!isPasswordStrongEnough) {
            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <>
                        <p>Αδύναμος κωδικός. </p>
                        <p className="text-sm mt-2">
                            Ο κωδικός σας πρέπει να αποτελείται από τουλάχιστον
                            8 χαρακτήρες και να περιλαμβάνει ένα κεφαλαίο
                            γράμμα, έναν αριθμό και έναν ειδικό χαρακτήρα (!, @,
                            #, $, %, ^, &, *).
                        </p>
                    </>
                ),
                type: 'error',
            }))
            return
        }

        const passwordsMatch = validatePassword(
            inputs.password,
            inputs.passwordVerification
        )

        if (!passwordsMatch) {
            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <>
                        <p>Οι κωδικοί δεν ταιριάζουν. </p>
                        <p>Παρακαλούμε προσπαθήστε ξανά.</p>
                    </>
                ),
                type: 'error',
            }))
            return
        }

        mutation.mutate()
    }

    const handleInputChange = e => {
        setInputs(values => ({ ...values, [e.target.name]: e.target.value }))
    }

    const divClasses = isMobile
        ? 'py-8 flex flex-col items-center'
        : 'py-16 px-28 text-center'

    const loginBody = (
        <div className="flex flex-col items-center md:m-20 m-10 md:w-auto w-full">
            <form
                className="flex flex-col w-full px-12 md:px-0 md:my-10"
                onSubmit={handleSubmit}
            >
                <FormField
                    required
                    type="password"
                    label="Νέος κωδικός"
                    name="password"
                    labelOnSide={false}
                    value={inputs.password}
                    onChange={handleInputChange}
                />

                <FormField
                    required
                    type="password"
                    label="Επιβεβαίωση κωδικού"
                    name="passwordVerification"
                    labelOnSide={false}
                    value={inputs.passwordVerification}
                    onChange={handleInputChange}
                />

                <div className="flex md:gap-8 justify-between md:py-12">
                    <button
                        disabled={mutation.isLoading}
                        type="submit"
                        className="disabled:cursor-not-allowed disabled:bg-opacity-50 m-auto bg-primary text-white border border-primary hover:bg-white hover:text-primary rounded-lg py-3.5 px-10 transition-all"
                    >
                        Αλλαγή κωδικού πρόσβασης
                    </button>
                </div>
            </form>
        </div>
    )

    return (
        <>
            {popup.shown && (
                <PopupMessage
                    message={popup.message}
                    type={popup.type}
                    closePopUp={() =>
                        setPopup(values => ({ ...values, shown: false }))
                    }
                    showCloseButton={true}
                />
            )}

            <main className="flex justify-center items-center m-3">
                <Modal classes=" w-full h-fit m-5 md:max-w-5xl md:m-12">
                    <Header includeLogo={true} withBorder={true} />

                    <div className={divClasses}>
                        <h1 className="font-bold  text-primary text-3xl ">
                            Αλλαγή κωδικού πρόσβασης
                        </h1>

                        {isMobile ? (
                            loginBody
                        ) : (
                            <Modal classes="max-w-10xl lg:m-24 min-w-max mx-8">
                                {loginBody}
                            </Modal>
                        )}
                    </div>
                </Modal>
            </main>

            <Footer />
        </>
    )
}

export default ResetAdminPasswordForm
