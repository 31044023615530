import PropTypes from 'prop-types'

const ProfileItem = ({ label, value }) => {
    return (
        <>
            <div className="profile-item py-1 flex w-full max-w-2xl border-b border-gray">
                <div className="w-1/2 h-max flex-auto px-8 my-1">
                    <p className="font-medium">{label}</p>
                </div>
                <div className="w-1/2 h-max flex-auto">
                    <p className="font-medium text-secondary">{value ?? '-'}</p>
                </div>
            </div>
        </>
    )
}

ProfileItem.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.oneOf([null]),
    ]),
    label: PropTypes.string,
}

export default ProfileItem
