export const InstantPaymentTypes = Object.freeze({
    hospitalityTax: 'HOSPITALITY_TAX',
    communalAreasTax: 'COMMUNAL_AREAS_TAX',
    waterBill: 'WATER_BILL',
    propertyContract: 'PROPERTY_CONTRACT',
    tombAndReconditioningPermit: 'TOMB_AND_RECONDITIONING_PERMIT',
    gravePurchase: 'GRAVE_PURCHASE',
})

export const PaymentFormTypes = Object.freeze({
    hospitalityTax: 'HOSPITALITY_TAX',
    communalAreasTax: 'COMMUNAL_AREAS_TAX',
    waterBill: 'WATER_BILL',
    propertyContract: 'PROPERTY_CONTRACT',
    tombAndReconditioningPermit: 'TOMB_AND_RECONDITIONING_PERMIT',
    gravePurchase: 'GRAVE_PURCHASE',
    other: 'OTHER',
})
