import { VscGlobe } from 'react-icons/vsc'
import { FiMail } from 'react-icons/fi'
import { MdOutlinePhone } from 'react-icons/md'

const contactItems = [
    {
        icon: MdOutlinePhone,
        htmlElement: (
            <a href="tel:2284061570" className="font-bold text-primary">
                22840 61570
            </a>
        ),
    },
    {
        icon: FiMail,
        htmlElement: (
            <a
                href="mailto:info@antiparos.gr"
                className="font-bold text-primary"
            >
                info@antiparos.gr
            </a>
        ),
    },
    {
        icon: VscGlobe,
        htmlElement: (
            <a
                href="https://antiparos.gr"
                target="_blank"
                rel="noopener noreferrer"
                className="font-bold text-primary"
            >
                antiparos.gr
            </a>
        ),
    },
]

const ContactInfo = () => {
    return (
        <div className="flex items-center flex-col md:flex-row gap-3 md:gap-8 justify-center pt-4 my-4 md:my-0">
            {contactItems.map(({ icon: Icon, htmlElement }, index) => (
                <div key={index} className="flex items-center space-x-2">
                    <Icon className="text-accent font-bold text-2xl" />
                    {htmlElement}
                </div>
            ))}
        </div>
    )
}

export default ContactInfo
