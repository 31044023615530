import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getProfile } from '../api/users'
import Roles from '../enums/Roles'
import Loading from '../components/Loading/Loading'

const useProfileCompleted = token => {
    const [profileCompleted, setProfileCompleted] = useState(false)

    const options = {
        headers: {
            Authorization: `Bearer ${token.access_token}`,
        },
    }

    const { data, isLoading } = useQuery({
        queryKey: ['profile', options],
        queryFn: () => getProfile(options),
    })

    if (isLoading) {
        return <Loading />
    }

    if (data && data.role === Roles.admin) {
        return true
    }

    if (data) {
        const isCompleted =
            data.profile.address &&
            data.profile.telephone &&
            data.profile.mobileNumber &&
            data.profile.residenceType
                ? true
                : false

        if (isCompleted !== profileCompleted) {
            setProfileCompleted(isCompleted)
        }
    }

    return profileCompleted
}

export default useProfileCompleted
