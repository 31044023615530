import { ResidenceTypes } from '../enums/ResidenceTypes'
import Roles from '../enums/Roles'

export function getRoleText(role) {
    switch (role) {
        case Roles.admin:
            return 'Διαχειριστής'
        case Roles.company:
            return 'Επιχείρηση'
        case Roles.citizen:
            return 'Πολίτης'
        default:
            break
    }
}

export function getResidentText(residentType) {
    switch (residentType) {
        case ResidenceTypes.resident:
            return 'Κάτοικος'
        case ResidenceTypes.citizen:
            return 'Δημότης'
        default:
            break
    }
}
