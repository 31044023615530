import { FaAsterisk } from 'react-icons/fa'
import PropTypes from 'prop-types'

const AsteriskCard = ({ htmlContent }) => {
    return (
        <div className="flex items-start p-4 border border-[#B2B2B2] rounded-lg bg-transparent my-8 flex-col gap-4 max-w-full md:max-w-[80%] w-full self-center">
            <FaAsterisk className="text-accent mt-1 mr-2" />
            <div
                className="text-gray-600 text-sm"
                dangerouslySetInnerHTML={{ __html: htmlContent }}
            />
        </div>
    )
}

AsteriskCard.propTypes = {
    htmlContent: PropTypes.string.isRequired,
}

export default AsteriskCard
