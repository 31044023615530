import editIcon from '../../assets/images/edit-red.svg'
import { ResidenceTypes } from '../../enums/ResidenceTypes'
import useMediaQuery from '../../hooks/useMediaQuery'
import PropTypes from 'prop-types'

const ProfileEditableItem = ({
    defaultValue,
    setterFunction,
    editing,
    setEditing,
    type,
    name,
    label,
}) => {
    const isLowerRes = useMediaQuery('(max-width:380px)')

    return (
        <>
            <div className="profile-item py-1 flex w-full max-w-2xl border-b border-gray break-all">
                <div className="w-1/2 h-max flex-auto px-8 my-1">
                    <p className="font-medium">{label}</p>
                </div>
                <div
                    className={`w-1/2 h-max flex-auto flex ${
                        isLowerRes ? 'flex-col' : null
                    } justify-between mr-4`}
                >
                    {editing ? (
                        name === 'residenceType' ? (
                            <select
                                className="border-gray-300 rounded-md border-2 bg-gray-100 w-full mr-10 p-2"
                                name={name}
                                onChange={e => setterFunction(e.target.value)}
                            >
                                <option value={ResidenceTypes.citizen}>
                                    Δημότης
                                </option>
                                <option value={ResidenceTypes.resident}>
                                    Κάτοικος
                                </option>
                            </select>
                        ) : (
                            <input
                                className="border-gray-300 rounded-md border-2 bg-gray-100 w-full mr-10 p-2"
                                type={type}
                                name={name}
                                defaultValue={defaultValue}
                                onChange={e => setterFunction(e.target.value)}
                            />
                        )
                    ) : (
                        <>
                            <p
                                className={`font-medium text-secondary ${
                                    isLowerRes ? 'text-center' : null
                                }`}
                            >
                                {defaultValue ?? '-'}
                            </p>

                            <button
                                className={
                                    isLowerRes ? 'self-center pt-2' : null
                                }
                                onClick={setEditing}
                            >
                                <img src={editIcon} alt="edit-button" />
                            </button>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

ProfileEditableItem.propTypes = {
    defaultValue: PropTypes.string,
    setterFunction: PropTypes.func,
    editing: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    setEditing: PropTypes.func,
    type: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
}

export default ProfileEditableItem
